import React from 'react';
import './App.css';

import Main from './components/Main'
import { PrivacyPolicy, TermsOfUse, Test } from './components/StaticPages'

import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";


const NoMatch = () => window.location = "https://api.gitembed.test/";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/test"><Test /></Route>
        <Route exact path="/terms-of-use"> <TermsOfUse /> </Route>
        <Route exact path="/privacy-policy"> <PrivacyPolicy /> </Route>
        <Route exact path="/"> <Main /> </Route>
        <Route path="*"> <NoMatch /> </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
