import React, { Component } from 'react'
import { Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import convert from 'htmr';

import Navigation from './Navigation'
import Footer from './Footer'
import { SelectLanguage, SelectStyle, SelectLineNumbers } from './Selectors'

const axios = require('axios').default;



class Snippet extends React.Component {
  constructor(props) {
    super(props);
    this.default_button_text = 'Copy snippet';
    this.state = {
      button_text: this.default_button_text,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.button_text !== this.default_button_text) {
      setTimeout(
        () => { this.setState({button_text: this.default_button_text})},
        2000
      )
    }
  }

  render() {
    return (
      <InputGroup className="snippet">
        <FormControl
          id="snippet"
          as="textarea"
          rows="3"
          placeholder="Paste this in your site"
          spellCheck="false"
          defaultValue={this.props.snippet && `<script src='${this.props.snippet}'></script>`}
        />
        <InputGroup.Append>
          <CopyToClipboard
            text={this.props.snippet && `<script src='${this.props.snippet}'></script>`}
            onCopy={() => this.setState({'button_text': 'Copied!'})}
          >
            <Button variant="secondary" id="clippy">{this.state.button_text}</Button>
          </CopyToClipboard>
        </InputGroup.Append>
      </InputGroup>
    )
  }
}

class TargetUrl extends React.Component {
  constructor(props) {
    super(props);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleFetchButtonClick = this.handleFetchButtonClick.bind(this);
  }

  handleUrlChange(e) {
    this.props.onUrlChange(e.target.value);
  }

  handleFetchButtonClick(click) {
    this.props.onFetchButtonClick();
  }

  render() {
    return (
      <InputGroup className="enter-url">
        <FormControl
          id="remote-url"
          as="input"
          placeholder="Enter GitHub, Gitlab or Bitbucket URL"
          size="lg"
          defaultValue=""
          spellCheck='false'
          onChange={this.handleUrlChange}
        />
        <InputGroup.Append>
          <Button
            id="remote-url-button"
            variant="secondary"
            onClick={this.handleFetchButtonClick}
          >
            Fetch
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }
}

class HighlightedHtml extends React.Component {
  render() {
    return this.props.html ? convert(this.props.html) : null;
  }
}

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      snippet: null,
      html: null,
      selected_lexer: null,
      selected_style: null,
      selected_linenos: null,
    };
    this.axios = axios.create({
      timeout: 5000,
      headers: {'Accept': 'application/json'}
    })

    this.onUrlChange = this.onUrlChange.bind(this);
    this.onLexerChange = this.onLexerChange.bind(this);
    this.onStyleChange = this.onStyleChange.bind(this);
    this.onLinenosChange = this.onLinenosChange.bind(this);
    this.onFetchButtonClick = this.onFetchButtonClick.bind(this);
  }

  onUrlChange(url) {
    this.setState({url: url});
  }
  onLexerChange(selected_lexer) {
    this.setState(
      {selected_lexer: selected_lexer},
      this.fetchHtml
    );
  }
  onStyleChange(selected_style) {
    this.setState(
      {selected_style: selected_style},
      this.fetchHtml
    );
  }
  onLinenosChange(selected_linenos) {
    this.setState(
      {selected_linenos: selected_linenos},
      this.fetchHtml
    );
  }
  onFetchButtonClick() {
    this.setState(
      {
        selected_lexer: null,
        selected_style: null,
        selected_linenos: null,
      },
      this.fetchHtml
    );
  }

  fetchHtml() {
    if (this.state.url == null) {
      return;
    }

    var gitembed_base = 'gitembed.com'
    var gitembed_protocol = 'https://'
    if(process.env.NODE_ENV === 'development') {
      gitembed_base = 'gitembed.test';
    }

    var requested_url = new URL(this.state.url);
    var gitembed_subdomain = null;

    switch(requested_url.hostname) {
      case 'github.com':
        gitembed_subdomain = 'github';
        break;
      case 'gitlab.com':
        gitembed_subdomain = 'gitlab';
        break;
      case 'bitbucket.org':
        gitembed_subdomain = 'bitbucket';
        break;
      default:
        throw new Error('Unsupported provider');
    }

    // This is the URL we hit in order to get back the
    // syntax highlighted file
    var gitembed_host = gitembed_subdomain + '.' + gitembed_base;
    var gitembed_full_url = gitembed_protocol + gitembed_host + requested_url.pathname;

    var params = {
      lexer: this.state.selected_lexer,
      style: this.state.selected_style,
      linenos: this.state.selected_linenos,
    };

    console.log(`Requesting ${gitembed_full_url} `, params);

    this.axios({
      method: 'get',
      url: gitembed_full_url,
      params: params
    })
      .then(response => {
        const createDOMPurify = require('dompurify');
        const { JSDOM } = require('jsdom');
        const window = new JSDOM('').window;
        const DOMPurify = createDOMPurify(window);

        console.log(response.data.html);
        const clean_html = DOMPurify.sanitize(response.data.html);
        console.log(`Lexer detected: ${response.data.lexer}`)
        console.log(`Style used in highlighting: ${response.data.style}`)
        console.log(`Clean HTML: ${clean_html}`);

        this.setState({
          html: clean_html,
          selected_lexer: response.data.lexer,
          selected_style: response.data.style,
          snippet: gitembed_full_url,
        });
      })
      .catch(function (error) {
        console.log('Error: ', error);
      })
      .then(function() {
        console.log('Request completed.');
      });
  }

  render() {
    return (
      <div>
        <div className="wrapper-top">
          <Navigation/>
          <Container>
            <Row>
              <Col xs={{ span: 10, offset: 1}}>
                <TargetUrl
                  onUrlChange={this.onUrlChange}
                  onFetchButtonClick={this.onFetchButtonClick}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 10, offset: 1}}>
                <Snippet snippet={this.state.snippet} />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="wrapper-mid">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="preview-text">
                  <div className="preview-title"><a href="#foo">REVIEW AND CUSTOMIZE</a> </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='wrapper-bottom'>
          <Container>
            <div className="review-and-customize">
              <Row>
                <Col xs={{ span: 10, offset: 1}}>
                  <div className="float-left">
                    <SelectLanguage onLexerChange={this.onLexerChange} selected_lexer={this.state.selected_lexer}/>
                  </div>
                  <div className="float-right">
                    <SelectStyle onStyleChange={this.onStyleChange} selected_style={this.state.selected_style}/>
                    <SelectLineNumbers onLinenosChange={this.onLinenosChange} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 10, offset: 1}}>
                  <div id="highlighted-html" className="file-contents-wrapper">
                    <HighlightedHtml html={this.state.html}/>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Main;
