import React from 'react'
import { Navbar, Container, Row, Col } from 'react-bootstrap';

import logo from '../images/logotype.png'

function Navigation(props) {
  return (
    <Container>
      <Row>
        <Col xs={{ span: 10, offset: 1}}>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              <img src={logo} alt="logotype"></img>
            </Navbar.Brand>
            <span className="top-blurb">
              <p className="top-byline">
                Embed and syntax highlight code, <br/>
                from any GitHub, Gitlab or Bitbucket repository
              </p>
              <span className="top-title">gitembed.com</span>
            </span>
          </Navbar>
        </Col>
      </Row>
    </Container>
  )
}

export default Navigation
