import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom"

import icon_facebook from '../images/social/facebook.png'
import icon_twitter from '../images/social/twitter.png'
import icon_github from '../images/social/github.png'

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={{ span: 10, offset: 1}}>
            <div className="footer-inner">
              <div className="social">
                <a href="https://facebook.com"> <img src={icon_facebook} alt="Facebook social icon"></img> </a>
                <a href="https://twitter.com/gitembed"> <img src={icon_twitter} alt="Twitter social icon"></img></a>
                <a href="https://github.com"> <img src={icon_github} alt="Github social icon"></img></a>
              </div>
              <span>
                Copyright © 2016-2020 GitEmbed.com <br/>
                <a href="mailto:feedback@gitembed.com">feedback@gitembed.com</a> <br/>
                <Link to="/terms-of-use">Terms of use</Link> {'\u2022'} <Link to="/privacy-policy">Privacy policy</Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;
