import React from 'react';
import Select from 'react-select';

const lexer_options = {
  'guess': 'Autodetect language',
  'abap': 'ABAP',
  'abnf': 'ABNF',
  'adl': 'ADL',
  'antlr': 'ANTLR',
  'antlr_with_as': 'ANTLR With ActionScript Target',
  'antlr_with_csharp': 'ANTLR With C# Target',
  'antlr_with_cpp': 'ANTLR With CPP Target',
  'antlr_with_java': 'ANTLR With Java Target',
  'antlr_with_objc': 'ANTLR With ObjectiveC Target',
  'antlr_with_perl': 'ANTLR With Perl Target',
  'antlr_with_python': 'ANTLR With Python Target',
  'antlr_with_ruby': 'ANTLR With Ruby Target',
  'apl': 'APL',
  'as': 'ActionScript',
  'as3': 'ActionScript 3',
  'ada': 'Ada',
  'agda': 'Agda',
  'alloy': 'Alloy',
  'at': 'AmbientTalk',
  'apacheconf': 'ApacheConf',
  'applescript': 'AppleScript',
  'arduino': 'Arduino',
  'aspectj': 'AspectJ',
  'asy': 'Asymptote',
  'autoit': 'AutoIt',
  'awk': 'Awk',
  'bbcode': 'BBCode',
  'bc': 'BC',
  'bnf': 'BNF',
  'bugs': 'BUGS',
  'basemake': 'Base Makefile',
  'bash': 'Bash',
  'console': 'Bash Session',
  'bat': 'Batchfile',
  'befunge': 'Befunge',
  'blitzbasic': 'BlitzBasic',
  'blitzmax': 'BlitzMax',
  'boo': 'Boo',
  'boogie': 'Boogie',
  'brainfuck': 'Brainfuck',
  'bro': 'Bro',
  'c': 'C',
  'csharp': 'C#',
  'cpp': 'C++',
  'camkes': 'CAmkES',
  'cbmbas': 'CBM BASIC V2',
  'cfengine3': 'CFEngine3',
  'cmake': 'CMake',
  'cobol': 'COBOL',
  'cobolfree': 'COBOLFree',
  'cpsa': 'CPSA',
  'css': 'CSS',
  'css_and_django': 'CSS+Django/Jinja',
  'css_and_genshitext': 'CSS+Genshi Text',
  'css_and_lasso': 'CSS+Lasso',
  'css_and_mako': 'CSS+Mako',
  'css_and_myghty': 'CSS+Myghty',
  'css_and_php': 'CSS+PHP',
  'css_and_erb': 'CSS+Ruby',
  'css_and_smarty': 'CSS+Smarty',
  'css_and_mozpreproc': 'CSS+mozpreproc',
  'cuda': 'CUDA',
  'ceylon': 'Ceylon',
  'chai': 'ChaiScript',
  'chapel': 'Chapel',
  'cheetah': 'Cheetah',
  'cirru': 'Cirru',
  'clay': 'Clay',
  'clojure': 'Clojure',
  'clojurescript': 'ClojureScript',
  'coffee_with_script': 'CoffeeScript',
  'cfc': 'Coldfusion CFC',
  'cfm': 'Coldfusion HTML',
  'common_with_lisp': 'Common Lisp',
  'componentpascal': 'Component Pascal',
  'coq': 'Coq',
  'crmsh': 'Crmsh',
  'croc': 'Croc',
  'cryptol': 'Cryptol',
  'csound_with_document': 'Csound Document',
  'csound': 'Csound Orchestra',
  'csound_with_score': 'Csound Score',
  'cypher': 'Cypher',
  'cython': 'Cython',
  'd': 'D',
  'dtd': 'DTD',
  'dpatch': 'Darcs Patch',
  'dart': 'Dart',
  'control': 'Debian Control file',
  'sourceslist': 'Debian Sourcelist',
  'delphi': 'Delphi',
  'diff': 'Diff',
  'django': 'Django/Jinja',
  'docker': 'Docker',
  'duel': 'Duel',
  'dylan': 'Dylan',
  'dylan_with_console': 'Dylan session',
  'dylan_with_lid': 'DylanLID',
  'ebnf': 'EBNF',
  'ecl': 'ECL',
  'erb': 'ERB',
  'earl_with_grey': 'Earl Grey',
  'easytrieve': 'Easytrieve',
  'eiffel': 'Eiffel',
  'elixir': 'Elixir',
  'iex': 'Elixir iex session',
  'elm': 'Elm',
  'emacs': 'EmacsLisp',
  'ragel_with_em': 'Embedded Ragel',
  'erlang': 'Erlang',
  'erl': 'Erlang erl session',
  'evoque': 'Evoque',
  'ezhil': 'Ezhil',
  'fsharp': 'FSharp',
  'factor': 'Factor',
  'fancy': 'Fancy',
  'fan': 'Fantom',
  'felix': 'Felix',
  'fish': 'Fish',
  'fortran': 'Fortran',
  'fortranfixed': 'FortranFixed',
  'foxpro': 'FoxPro',
  'gap': 'GAP',
  'gas': 'GAS',
  'glsl': 'GLSL',
  'genshi': 'Genshi',
  'genshitext': 'Genshi Text',
  'pot': 'Gettext Catalog',
  'cucumber': 'Gherkin',
  'gnuplot': 'Gnuplot',
  'go': 'Go',
  'golo': 'Golo',
  'gooddata_with_cl': 'GoodData-CL',
  'gosu': 'Gosu',
  'gst': 'Gosu Template',
  'groff': 'Groff',
  'groovy': 'Groovy',
  'html': 'HTML',
  'html_and_cheetah': 'HTML+Cheetah',
  'html_and_django': 'HTML+Django/Jinja',
  'html_and_evoque': 'HTML+Evoque',
  'html_and_genshi': 'HTML+Genshi',
  'html_and_handlebars': 'HTML+Handlebars',
  'html_and_lasso': 'HTML+Lasso',
  'html_and_mako': 'HTML+Mako',
  'html_and_myghty': 'HTML+Myghty',
  'html_and_php': 'HTML+PHP',
  'html_and_smarty': 'HTML+Smarty',
  'html_and_twig': 'HTML+Twig',
  'html_and_velocity': 'HTML+Velocity',
  'http': 'HTTP',
  'haml': 'Haml',
  'handlebars': 'Handlebars',
  'haskell': 'Haskell',
  'hx': 'Haxe',
  'hexdump': 'Hexdump',
  'haxeml': 'Hxml',
  'hylang': 'Hy',
  'hybris': 'Hybris',
  'idl': 'IDL',
  'ini': 'INI',
  'ipython2': 'IPython',
  'ipythonconsole': 'IPython console session',
  'ipython3': 'IPython3',
  'irc': 'IRC logs',
  'idris': 'Idris',
  'igor': 'Igor',
  'inform6': 'Inform 6',
  'i6t': 'Inform 6 template',
  'inform7': 'Inform 7',
  'io': 'Io',
  'ioke': 'Ioke',
  'isabelle': 'Isabelle',
  'j': 'J',
  'jags': 'JAGS',
  'jcl': 'JCL',
  'json': 'JSON',
  'jsonld': 'JSON-LD',
  'jade': 'Jade',
  'jasmin': 'Jasmin',
  'java': 'Java',
  'jsp': 'Java Server Page',
  'js': 'JavaScript',
  'js_and_cheetah': 'JavaScript+Cheetah',
  'js_and_django': 'JavaScript+Django/Jinja',
  'js_and_genshitext': 'JavaScript+Genshi Text',
  'js_and_lasso': 'JavaScript+Lasso',
  'js_and_mako': 'JavaScript+Mako',
  'js_and_myghty': 'JavaScript+Myghty',
  'js_and_php': 'JavaScript+PHP',
  'js_and_erb': 'JavaScript+Ruby',
  'js_and_smarty': 'JavaScript+Smarty',
  'javascript_and_mozpreproc': 'Javascript+mozpreproc',
  'julia': 'Julia',
  'jlcon': 'Julia console',
  'kal': 'Kal',
  'kconfig': 'Kconfig',
  'koka': 'Koka',
  'kotlin': 'Kotlin',
  'llvm': 'LLVM',
  'lsl': 'LSL',
  'lasso': 'Lasso',
  'lean': 'Lean',
  'less': 'LessCss',
  'lighty': 'Lighttpd configuration file',
  'limbo': 'Limbo',
  'lagda': 'Literate Agda',
  'lcry': 'Literate Cryptol',
  'lhs': 'Literate Haskell',
  'lidr': 'Literate Idris',
  'live_with_script': 'LiveScript',
  'logos': 'Logos',
  'logtalk': 'Logtalk',
  'lua': 'Lua',
  'maql': 'MAQL',
  'moocode': 'MOOCode',
  'mql': 'MQL',
  'doscon': 'MSDOS Session',
  'mxml': 'MXML',
  'make': 'Makefile',
  'mako': 'Mako',
  'mask': 'Mask',
  'mason': 'Mason',
  'mathematica': 'Mathematica',
  'matlab': 'Matlab',
  'matlabsession': 'Matlab session',
  'minid': 'MiniD',
  'modelica': 'Modelica',
  'modula2': 'Modula-2',
  'trac_with_wiki': 'MoinMoin/Trac Wiki markup',
  'monkey': 'Monkey',
  'moon': 'MoonScript',
  'mscgen': 'Mscgen',
  'mupad': 'MuPAD',
  'mysql': 'MySQL',
  'myghty': 'Myghty',
  'nasm': 'NASM',
  'nsis': 'NSIS',
  'nemerle': 'Nemerle',
  'newlisp': 'NewLisp',
  'newspeak': 'Newspeak',
  'nginx': 'Nginx configuration file',
  'nimrod': 'Nimrod',
  'nit': 'Nit',
  'nixos': 'Nix',
  'numpy': 'NumPy',
  'ocaml': 'OCaml',
  'odin': 'ODIN',
  'objective_with_c': 'Objective-C',
  'objective_with_c_and__and_': 'Objective-C++',
  'objective_with_j': 'Objective-J',
  'octave': 'Octave',
  'ooc': 'Ooc',
  'opa': 'Opa',
  'openedge': 'OpenEdge ABL',
  'php': 'PHP',
  'plpgsql': 'PL/pgSQL',
  'pov': 'POVRay',
  'pacmanconf': 'PacmanConf',
  'pan': 'Pan',
  'parasail': 'ParaSail',
  'pawn': 'Pawn',
  'perl': 'Perl',
  'perl6': 'Perl6',
  'pig': 'Pig',
  'pike': 'Pike',
  'pkgconfig': 'PkgConfig',
  'postscript': 'PostScript',
  'postgresql': 'PostgreSQL SQL dialect',
  'psql': 'PostgreSQL console (psql)',
  'powershell': 'PowerShell',
  'ps1con': 'PowerShell Session',
  'praat': 'Praat',
  'prolog': 'Prolog',
  'properties': 'Properties',
  'protobuf': 'Protocol Buffer',
  'puppet': 'Puppet',
  'pypylog': 'PyPy Log',
  'python': 'Python',
  'python3': 'Python 3',
  'py3tb': 'Python 3.0 Traceback',
  'pytb': 'Python Traceback',
  'pycon': 'Python console session',
  'qbasic': 'QBasic',
  'qml': 'QML',
  'qvto': 'QVTO',
  'rconsole': 'RConsole',
  'rebol': 'REBOL',
  'rhtml': 'RHTML',
  'spec': 'RPMSpec',
  'rql': 'RQL',
  'rsl': 'RSL',
  'racket': 'Racket',
  'ragel': 'Ragel',
  'ragel_with_c': 'Ragel in C Host',
  'ragel_with_cpp': 'Ragel in CPP Host',
  'ragel_with_d': 'Ragel in D Host',
  'ragel_with_java': 'Ragel in Java Host',
  'ragel_with_objc': 'Ragel in Objective C Host',
  'ragel_with_ruby': 'Ragel in Ruby Host',
  'raw': 'Raw token data',
  'rd': 'Rd',
  'red': 'Red',
  'redcode': 'Redcode',
  'resource': 'ResourceBundle',
  'rexx': 'Rexx',
  'roboconf_with_graph': 'Roboconf Graph',
  'roboconf_with_instances': 'Roboconf Instances',
  'robotframework': 'RobotFramework',
  'rb': 'Ruby',
  'rbcon': 'Ruby irb session',
  'rust': 'Rust',
  'splus': 'S',
  'scss': 'SCSS',
  'sparql': 'SPARQL',
  'sql': 'SQL',
  'swig': 'SWIG',
  'sass': 'Sass',
  'scala': 'Scala',
  'ssp': 'Scalate Server Page',
  'scaml': 'Scaml',
  'scheme': 'Scheme',
  'scilab': 'Scilab',
  'shen': 'Shen',
  'slim': 'Slim',
  'smali': 'Smali',
  'smalltalk': 'Smalltalk',
  'smarty': 'Smarty',
  'snobol': 'Snobol',
  'sp': 'SourcePawn',
  'squidconf': 'SquidConf',
  'stan': 'Stan',
  'sml': 'Standard ML',
  'sc': 'SuperCollider',
  'swift': 'Swift',
  'tads3': 'TADS 3',
  'tap': 'TAP',
  'tcl': 'Tcl',
  'tcsh': 'Tcsh',
  'tcshcon': 'Tcsh Session',
  'tex': 'TeX',
  'tea': 'Tea',
  'termcap': 'Termcap',
  'terminfo': 'Terminfo',
  'terraform': 'Terraform',
  'text': 'Text only',
  'thrift': 'Thrift',
  'todotxt': 'Todotxt',
  'rts': 'TrafficScript',
  'treetop': 'Treetop',
  'turtle': 'Turtle',
  'twig': 'Twig',
  'ts': 'TypeScript',
  'urbiscript': 'UrbiScript',
  'vb.net': 'VB.net',
  'vctreestatus': 'VCTreeStatus',
  'vgl': 'VGL',
  'vala': 'Vala',
  'velocity': 'Velocity',
  'vim': 'VimL',
  'x10': 'X10',
  'xml': 'XML',
  'xml_and_cheetah': 'XML+Cheetah',
  'xml_and_django': 'XML+Django/Jinja',
  'xml_and_evoque': 'XML+Evoque',
  'xml_and_lasso': 'XML+Lasso',
  'xml_and_mako': 'XML+Mako',
  'xml_and_myghty': 'XML+Myghty',
  'xml_and_php': 'XML+PHP',
  'xml_and_erb': 'XML+Ruby',
  'xml_and_smarty': 'XML+Smarty',
  'xml_and_velocity': 'XML+Velocity',
  'xquery': 'XQuery',
  'xslt': 'XSLT',
  'xul_and_mozpreproc': 'XUL+mozpreproc',
  'xtend': 'Xtend',
  'yaml': 'YAML',
  'yaml_and_jinja': 'YAML+Jinja',
  'zephir': 'Zephir',
  'aspx_with_cs': 'aspx-cs',
  'aspx_with_vb': 'aspx-vb',
  'ahk': 'autohotkey',
  'c_with_objdump': 'c-objdump',
  'cadl': 'cADL',
  'ca65': 'ca65 assembler',
  'cfs': 'cfstatement',
  'cpp_with_objdump': 'cpp-objdump',
  'd_with_objdump': 'd-objdump',
  'dg': 'dg',
  'ec': 'eC',
  'liquid': 'liquid',
  'mozhashpreproc': 'mozhashpreproc',
  'mozpercentpreproc': 'mozpercentpreproc',
  'nesc': 'nesC',
  'objdump': 'objdump',
  'objdump_with_nasm': 'objdump-nasm',
  'rst': 'reStructuredText',
  'registry': 'reg',
  'sqlite3': 'sqlite3con',
  'systemverilog': 'systemverilog',
  'verilog': 'verilog',
  'vhdl': 'vhdl',
};


const style_options = {
  'algol': 'Algol',
  'algol_nu': 'Algol_Nu',
  'paraiso-light': 'Paraiso-Light',
  'colorful': 'Colorful',
  'emacs': 'Emacs',
  'monokai': 'Monokai',
  'fruity': 'Fruity',
  'xcode': 'Xcode',
  'tango': 'Tango',
  'rrt': 'Rrt',
  'vs': 'Vs',
  'native': 'Native',
  'bw': 'Bw',
  'trac': 'Trac',
  'borland': 'Borland',
  'perldoc': 'Perldoc',
  'murphy': 'Murphy',
  'pastie': 'Pastie',
  'autumn': 'Autumn',
  'manni': 'Manni',
  'lovelace': 'Lovelace',
  'friendly': 'Friendly',
  'igor': 'Igor',
  'vim': 'Vim',
  'paraiso-dark': 'Paraiso-Dark',
  'default': 'Default style',
}

class SelectLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.options_dict = lexer_options;
    let options = []
    for (let key in this.options_dict) {
      let option_dict = {value: key, label: this.options_dict[key]};
      options.push(option_dict);
    }
    this.options = options;
  }

  handleChange(selected) {
    this.props.onLexerChange(selected.value);
  }

  render() {
    let option_dict = null;
    if (this.props.selected_lexer) {
      option_dict = {
        value: this.props.selected_lexer,
        label: this.options_dict[this.props.selected_lexer],
      };
    }

    console.log('Frontend lexer:', option_dict);

    return(
      <Select
        id="lexer-select"
        options={this.options}
        onChange={this.handleChange}
        className="width-300"
        placeholder="Select lexer..."
        value={option_dict}
        isSearchable
      />
    );
  }
}

class SelectStyle extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.options_dict = style_options;
    let options_arr = []
    for (let key in this.options_dict) {
      let option_dict = {value: key, label: this.options_dict[key]};
      options_arr.push(option_dict);
    }
    this.options = options_arr;
  }

  handleChange(selected) {
    this.props.onStyleChange(selected.value);
  }

  render() {
    console.log(this.props.selected_style);
    console.log(this.options_dict[this.props.selected_style]);

    let option_dict = null;
    if (this.props.selected_style) {
      option_dict = {
        value: this.props.selected_style,
        label: this.options_dict[this.props.selected_style],
      };
    }

    console.log('Frontend style:', option_dict);
    return(
      <Select
        id="style-select"
        options={this.options}
        onChange={this.handleChange}
        className="width-200 float-right"
        placeholder="Select style..."
        value={option_dict}
        isSearchable
      />
    );
  }
}

class SelectLineNumbers extends React.Component {
  constructor(props) {
    super(props);

    this.show = { value: 'yes', label: 'Show line numbers' };
    this.hide = { value: 'no', label: 'Hide line numbers' };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.onLinenosChange(this.hide.value);
  }

  handleChange(selected) {
    this.props.onLinenosChange(selected.value);
  }

  render() {
    return(
      <Select
        id="linenos-select"
        options={[this.hide, this.show]}
        onChange={this.handleChange}
        defaultValue={this.hide}
        className="width-200 float-right"
      />
    );
  }
}

export { SelectLanguage, SelectStyle, SelectLineNumbers };
